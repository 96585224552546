<template>
  <div class="d-flex h-full">
    <template v-if="showName">
      <v-col
        cols="5"
        class="pa-0">
        <CurvedHeader
          title="LET’S BEGIN"
          subtitle="What’s your first name?"
          :organization-logo="organizationLogo" />
      </v-col>
      <v-col
        cols="4"
        class="pa-16 mx-auto d-flex flex-column justify-center h-full">
        <v-text-field
          class="flex-grow-0 my-auto"
          color="primaryColor"
          :rules="rules.name"
          v-model="firstName"
          label="First Name">
          <v-icon left>mdi-chevron-right</v-icon>
        </v-text-field>
        <div class="d-flex align-center">
          <v-btn
            large
            rounded
            depressed
            class="white primaryColor--text"
            @click="$router.back()"
            >Go Back</v-btn
          >
          <v-btn
            class="ml-8"
            width="80%"
            large
            color="primaryColor white--text"
            @click="toggleShow()"
            rounded
            :disabled="isSubmitNameDisabled"
            depressed
            >Continue<v-icon
              width="7px"
              height="14px"
              right
              >mdi-arrow-right-thin</v-icon
            ></v-btn
          >
        </div>
      </v-col>
    </template>
    <template v-else>
      <v-col
        cols="5"
        class="pa-0">
        <CurvedHeader
          title="LET’S BEGIN"
          :organization-logo="organizationLogo"
          subtitle="What do you want to work on with Luminate? Choose as many as you like:" />
      </v-col>
      <v-col
        cols="4"
        class="pa-16 mx-auto d-flex flex-column justify-center h-full">
        <div
          v-if="categories && !isLoading"
          class="category-grid my-auto">
          <div
            class="category-container"
            v-for="category in categories"
            :key="category.name"
            @click="selectCategory(category.id)">
            <div
              class="category-overlay"
              v-if="selectedCategories.includes(category.id)">
              <v-icon
                class="category-selected-icon"
                color="black"
                >mdi-check</v-icon
              >
            </div>
            <div class="category-item">
              <v-img
                :src="category.coverUrl"
                cover
                class="category-cover mb-2" />
              <div
                :name="category.name"
                class="category-name px-2">
                {{ category.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-center">
          <v-btn
            large
            rounded
            depressed
            class="white primaryColor--text"
            @click="toggleShow()"
            >Go Back
          </v-btn>
          <v-btn
            width="80%"
            large
            rounded
            depressed
            class="ml-8"
            color="primaryColor white--text"
            @click="submit"
            :disabled="isSubmitCategoryDisabled"
            :loading="isSubmitLoading"
            >Continue
            <v-icon
              width="7px"
              height="14px"
              right
              >mdi-arrow-right-thin</v-icon
            >
          </v-btn>
        </div>
      </v-col>
    </template>
  </div>
</template>

<script>
  import ChooseCategoryMixin from '@/views/auth/choose-category/ChooseCategory.mixin.vue';
  import CurvedHeader from '@/components/general/curved-header/CurvedHeader.vue';
  export default {
    mixins: [ChooseCategoryMixin],
    components: {
      CurvedHeader,
    },
    data() {
      return {
        showName: true,
      };
    },
    methods: {
      toggleShow() {
        this.showName = !this.showName;
      },
    },
    computed: {
      isSubmitNameDisabled() {
        return !this.name?.length;
      },
      isSubmitCategoryDisabled() {
        return !this.selectedCategories.length;
      },
    },
  };
</script>

<style lang="css" scoped>
  .category-grid {
    display: grid;
    grid-template-columns: repeat(3, 96px);
    column-gap: 28px;
    row-gap: 16px;
  }
  .category-container {
    position: relative;
  }
  .category-item {
    text-align: center;
  }

  .category-cover {
    width: 96px;
    height: 96px;
    border-radius: 16px;
  }
  .category-name {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: var(--v-brandGrey1-base);
  }

  .category-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 96px;
    height: 96px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    border-radius: 16px;
    background-color: rgb(0 0 0 / 38%);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .category-selected-icon {
    background-color: rgb(255 255 255 / 38%);
    border-radius: 100%;
  }
  .v-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
  }
</style>
