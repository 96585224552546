<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  import AppBar from '@/components/general/app-bar/AppBar.vue';
  import CategoryItem from '@/components/categories/category-item/CategoryItem.vue';
  import userFSDocumentMixin from '@/mixins/userFSDocumentMixin';
  export default {
    components: {
      AppBar,
      CategoryItem,
    },
    async created() {
      await this.fetchUser();
      await this.fetchCategories();
    },
    mixins: [userFSDocumentMixin],
    data() {
      return {
        isLoading: false,
        name: '',
        selectedCategories: [],
        isSubmitLoading: false,
        rules: {
          name: [val => !val || !val.includes(' ') || 'The first name must be one word'],
        },
      };
    },
    watch: {
      userFSDocument() {
        this.name = this.userFSDocument.name;
        this.selectedCategories = [...this.userFSDocument.favoriteCategories];
      },
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage', 'setSuccessSnackbarMessage']),
      ...mapActions('CategoriesModule', ['fetchAll']),
      ...mapActions('AuthModule', ['updateUserData']),
      selectCategory(id) {
        if (this.selectedCategories.includes(id)) {
          this.selectedCategories = this.selectedCategories.filter(categoryId => categoryId != id);
        } else {
          this.selectedCategories.push(id);
        }
      },
      async fetchCategories() {
        this.isLoading = true;
        try {
          await this.fetchAll();
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isLoading = false;
        }
      },
      async submit() {
        try {
          this.isSubmitLoading = true;
          const data = {
            uid: this.getCurrentUserFromFirebaseAuth.uid,
            name: this.name,
            favoriteCategories: this.selectedCategories,
          };
          await this.updateUserData(data);
          this.$router.push({ name: 'Tutorial' });
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isSubmitLoading = false;
        }
      },
    },
    computed: {
      ...mapGetters('CategoriesModule', ['categories']),
      ...mapGetters('AuthModule', ['getCurrentUserFromFirebaseAuth']),
      ...mapGetters('OrganizationsModule', {
        organizationLogo: 'getOrganizationLogoUrl',
      }),
      isSubmitDisabled() {
        return !this.name?.length || !this.selectedCategories.length;
      },
      firstName: {
        get() {
          return this.name?.replace(/\s/g, '');
        },
        set(value) {
          this.name = value?.replace(/\s/g, '');
        },
      },
    },
  };
</script>
